<template>
  <div id="page_not_found">
    <section class="bg-cl-secondary py35 px20">
      <div class="container">
        <h2>
          {{ "Something went wrong ..." }}
        </h2>
      </div>
    </section>
    <section class="bg-cl-primary py35 px20">
      <div class="container">
        <div class="lh16 h5 weight-400">
          <p>
            {{ "We've noticed Internal Server Error while rendering this request." }}
          </p>
          <p>
            If you need an assistance you can drop us a line on
            <a href="mailto:contributors@vuestorefront.io" class="cl-secondary no-underline">
              a contact page
            </a>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Error',
  asyncData ({ store, route, context }) { // this is for SSR purposes to prefetch data
    return new Promise((resolve, reject) => {
      console.log('Calling asyncData for Error page ' + new Date())
      if (context) {
        context.output.cacheTags.add(`error`)
      }
      resolve()
    })
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || 'Internal Server Error 500',
      meta: this.$route.meta.description ? [{ vmid: 'description', description: this.$route.meta.description }] : []
    }
  }
}
</script>

<style scoped>
  a {
    text-decoration: underline;
  }
</style>
